export const COLUMNS_HIJAS = [
  {
    Header: 'Número de ticket',
    accessor: 'no_ticket',
  },
  {
    Header: 'Tipo de solicitud',
    accessor: 'subcategoria',
  },
  {
    Header: 'criticidad',
    accessor: 'nivel_criticidad',
  },
  {
    Header: 'dependencia',
    accessor: 'nombre_dependencia'
  },
  {
    Header: 'estado',
    accessor: 'estado'
  },
  {
    Header: 'liberar',
  },
]