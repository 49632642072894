export const MODIFICACION_COLUMNS = [
  {
    Header: 'modificado',
    accessor: 'idModificacion',
  },
  {
    Header: 'Atendido',
    accessor: 'fechaAtencion',
  },
  {
    Header: 'motivo',
    accessor: 'motivo',
  },
  {
    Header: 'detalles',
    accessor: 'detalles'
  },
  {
    Header: 'captura Solicitud',
    accessor: 'capturaSolicitud'
  },
]