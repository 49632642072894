export const COLUMNS = [
  {
    Header: 'Número de ticket',
    accessor: 'noTicket',
  },
  {
    Header: 'calificacion Caso',
    accessor: 'calificacionCaso',
  },
  {
    Header: 'calificacion Tecnico',
    accessor: 'calificacionTecnico'
  },
  {
    Header: 'fecha de respuesta',
    accessor: 'fecha_respuesta'
  },
  {
    Header: 'Caso cerrado',
    accessor: 'cerrarCaso',
  }
]